import { useState, useEffect, Dispatch } from "react";
import { UserInterface } from "../../../middleware/interfaces/user/user-interface";
import { FolderCreateInterface } from "../../../middleware/interfaces/folder/folder-create-interface";
import { FormatDateBE } from "../../../helper/general/methods";
import { FolderInterface } from "../../../middleware/interfaces/folder/folder-interface";
import { DecisionInterface } from "../../../middleware/interfaces/user/decision-interface";

interface folderDialogProps {
  reveal: string;
  user: UserInterface;
  setFolderDialogReveal: Dispatch<React.SetStateAction<string>>;
  selectedFolder: FolderInterface | undefined | null;
  dispatch: Dispatch<any>;
}

const FolderDialog: React.FC<folderDialogProps> = ({
  reveal,
  user,
  setFolderDialogReveal,
  selectedFolder,
  dispatch,
}) => {
  const [folderName, setFolderName] = useState<string>("");

  const HandleCancelClick = () => {
    setFolderDialogReveal("");
  };

  const CreateFolder = () => {
    const folder: FolderCreateInterface = {
      email: user.email,
      projectID: user.openProject?.projectID!,
      projectName: user.openProject?.projectName!,
      projectUserRole: user.openProject?.projectUserRole!,
      folderParentID: user.openFolder?.folderID,
      folderName: folderName,
      folderCreationDate: FormatDateBE(new Date()),
      type: "FolderCreateInterface",
    };
    dispatch({ type: "CREATE_FOLDER", payload: folder });
    setFolderDialogReveal("");
  };

  const EditFolder = () => {
    setFolderDialogReveal("");
    const decision: DecisionInterface = {
      title: "Edit Folder",
      message:
        "You have selected a folder to edit. Are you sure you want to continue?",
      decisionOptions: ["EDIT_FOLDER", "NO_ACTION"],
      result: "",
      data: {
        email: user.email,
        projectUserRole: user.openProject?.projectUserRole!,
        projectID: selectedFolder?.projectID!,
        folderName: folderName,
        folderID: selectedFolder?.folderID,
        type: "FolderEditInterface",
      },
      type: "DecisionInterface",
    };
    dispatch({ type: "DECISION", decision: decision });
  };

  useEffect(() => {
    if (reveal === "") {
      setFolderName("");
    } else if (reveal === "EDIT") {
      setFolderName(selectedFolder?.folderName!);
    }
  }, [reveal]);

  if (!reveal) {
    return null;
  }
  if (reveal === "CREATE") {
    return (
      <div className="dialogCover">
        <div className="dialog">
          <h2 className="dialogTitle">FOLDER</h2>
          <div className="dialogValueWrapper">
            <span className="dialogInputLabel">Name:</span>
            <input
              type="text"
              name="folderName"
              placeholder="Folder Name"
              value={folderName}
              onChange={(e) => setFolderName(e.target.value)}
            />
          </div>
          <div className="dialogButtonWrapper">
            <input
              type="button"
              name="cancel"
              value="Cancel"
              onClick={HandleCancelClick}
            />
            <input
              type="button"
              name="create"
              value="Create"
              onClick={CreateFolder}
            />
          </div>
        </div>
      </div>
    );
  } else if (reveal === "EDIT") {
    return (
      <div className="dialogCover">
        <div className="dialog">
          <h2 className="dialogTitle">FOLDER</h2>
          <div className="dialogValueWrapper">
            <span className="dialogInputLabel">Name:</span>
            <input
              type="text"
              name="folderName"
              placeholder="Folder Name"
              value={folderName}
              onChange={(e) => setFolderName(e.target.value)}
            />
          </div>
          <div className="dialogButtonWrapper">
            <input
              type="button"
              name="cancel"
              value="Cancel"
              onClick={HandleCancelClick}
            />
            <input
              type="button"
              name="edit"
              value="Edit"
              onClick={EditFolder}
            />
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default FolderDialog;
