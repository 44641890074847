import axios from "axios";
import { StateInterface } from "../../../middleware/interfaces/user/state-interface";
import { ProjectDeleteInterface } from "../../../middleware/interfaces/project/project-delete-interface";

export const ProjectDelete = {
  Delete: async (state: StateInterface) => {
    let serverResponse;
    if (state.data) {
      const ProjectDeleteData: ProjectDeleteInterface = state.data;
      const url = "./php/common/deleter-proxy.php";
      await axios
        .post(url, ProjectDeleteData)
        .then((response) => {
          serverResponse = response.data;
        })
        .catch((error) => {
          serverResponse = error.message;
        });
    } else {
      serverResponse = "SOMETHING WENT WRONG! PLEASE CONTACT ADMIN.";
    }

    return serverResponse;
  },
};
