import axios from "axios";
import { StateInterface } from "../../../middleware/interfaces/user/state-interface";
import { ReportElementNoteUploadInterface } from "../../../middleware/interfaces/report/report-element-note-upload-interface";

export const ReportElementNoteUpload = {
  Upload: async (state: StateInterface) => {
    let serverResponse;
    if (state.data) {
      const reportElementNoteUploadData: ReportElementNoteUploadInterface =
        state.data;
      const url = "./php/report/report-element-note-uploader-proxy.php";
      await axios
        .post(url, reportElementNoteUploadData)
        .then((response) => {
          serverResponse = response.data;
        })
        .catch((error) => {
          serverResponse = error.message;
        });
    } else {
      serverResponse = "SOMETHING WENT WRONG! PLEASE CONTACT ADMIN.";
    }
    return serverResponse;
  },
};
