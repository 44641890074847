import { Dispatch, FC } from "react";
import { ReportElementInterface } from "../../../middleware/interfaces/report/report-element-interface";
import { ReportElementNoteInterface } from "../../../middleware/interfaces/report/report-element-note-interface";

interface reportElementNotePanelProps {
  reportElement: ReportElementInterface | null;
  setSelectedReportElementNote: Dispatch<ReportElementNoteInterface | null>;
}

const ReportElementNotePanel: FC<reportElementNotePanelProps> = ({
  reportElement,
  setSelectedReportElementNote,
}) => {
  const HandleReportElementNoteDoubleClick = (
    reportElementNote: ReportElementNoteInterface
  ) => {
    setSelectedReportElementNote(reportElementNote);
  };

  return (
    <>
      {(() => {
        if (reportElement) {
          return (
            <div id="reportElementNotePanelWrapper">
              {reportElement.reportElementNotes.map((reportElementNote) => (
                <span
                  key={reportElementNote.reportElementNoteID}
                  className="reportElementNote"
                  onDoubleClick={() =>
                    HandleReportElementNoteDoubleClick(reportElementNote)
                  }
                >
                  {reportElementNote.reportElementNoteDate} |{" "}
                  {reportElementNote.email} :{" "}
                  {reportElementNote.reportElementNote}
                  {reportElementNote.reportElementNoteEditDate && " (EDITED)"}
                </span>
              ))}
            </div>
          );
        } else {
          return <></>;
        }
      })()}
    </>
  );
};

export default ReportElementNotePanel;
