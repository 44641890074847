import { ReportDescriptionInterface } from "../report/report-description-interface";
import { ReportInterface } from "../report/report-interface";
import { DecisionInterface } from "./decision-interface";
import { UserInterface } from "./user-interface";

export interface StateInterface {
  user: UserInterface | null;
  data?: any;
  response?: any;
  openModel?: any;
  openReport?: ReportInterface | null;
  openReportDescription?: ReportDescriptionInterface | null;
  decision?: DecisionInterface | null;
  type: "StateInterface";
}

export const initialState: StateInterface = {
  user: null,
  type: "StateInterface",
};
