import { Dispatch, FC, useState } from "react";
import { ReportElementInterface } from "../../../middleware/interfaces/report/report-element-interface";
import {
  StopEventPropagation,
  UpdateUserLastActionDate,
} from "../../../helper/ui/methods";
import { UserInterface } from "../../../middleware/interfaces/user/user-interface";

interface reportElementProps {
  user: UserInterface;
  reportElement: ReportElementInterface;
  userInputElementCount: number;
  selectedReportElement: ReportElementInterface | null;
  setSelectedReportElement: Dispatch<ReportElementInterface | null>;
  setReportElementNoteDialogReveal: Dispatch<boolean>;
  filter: boolean;
  dispatch: Dispatch<any>;
}

const ReportElement: FC<reportElementProps> = ({
  user,
  reportElement,
  userInputElementCount,
  selectedReportElement,
  setSelectedReportElement,
  setReportElementNoteDialogReveal,
  filter,
  dispatch,
}) => {
  const [reportImageMaximize, setReportImageMaximize] = useState(false);
  const [reportDataMaximize, setReportDataMaximize] = useState(false);

  const replacements = ["|", ":"];

  const SetReportElementStyle = () => {
    return {
      "--reportElementColor": reportElement.reportElementColor.startsWith("#")
        ? reportElement.reportElementColor
        : "#" + reportElement.reportElementColor,
      "--reportElementWidth": `calc(${100 / userInputElementCount}% - 2%)`,
    } as React.CSSProperties;
  };

  const SetReportElementHeaderStyle = () => {
    return {
      "--reportElementHeaderColor":
        selectedReportElement?.reportElementID === reportElement.reportElementID
          ? "white"
          : "transparent",
      "--reportElementHeaderFontWeight":
        selectedReportElement?.reportElementID === reportElement.reportElementID
          ? "bold"
          : "normal",
    } as React.CSSProperties;
  };

  const SetReportElementBodyStyle = () => {
    const isImageMax = reportImageMaximize && !reportDataMaximize;
    const isDataMax = !reportImageMaximize && reportDataMaximize;

    return {
      "--reportElementBodyMaxHeight":
        isImageMax || !reportDataMaximize ? "100%" : "100%",
      "--reportElementBodyHeight":
        isDataMax || !reportImageMaximize ? "100%" : "100%",
    } as React.CSSProperties;
  };

  const SetReportElementImageStyle = () => {
    if (reportImageMaximize) {
      return {
        "--reportElementImageWidth": "100%",
      } as React.CSSProperties;
    } else {
      return {
        "--reportElementImageWidth": "50%",
      } as React.CSSProperties;
    }
  };

  const SetReportElementDataStyle = () => {
    if (reportDataMaximize) {
      return {
        "--reportElementDataWidth": "100%",
      } as React.CSSProperties;
    } else {
      return {
        "--reportElementDataWidth": "50%",
      } as React.CSSProperties;
    }
  };

  const HandleReportElementClick = (event: React.MouseEvent) => {
    StopEventPropagation(event);
    UpdateUserLastActionDate(user, dispatch);
  };

  const HandleReportElementDoubleClick = (event: React.MouseEvent) => {
    StopEventPropagation(event);
  };

  const HandleReportElementImageDoubleClick = () => {
    setReportImageMaximize(!reportImageMaximize);
  };

  const HandleReportElementDataDoubleClick = () => {
    setReportDataMaximize(!reportDataMaximize);
  };

  const HandleReportElementHeaderClick = () => {
    setSelectedReportElement(reportElement);
  };

  const HandleReportElementHeaderDoubleClick = () => {
    setReportElementNoteDialogReveal(true);
  };

  return (
    <>
      {!filter ? (
        <></>
      ) : (
        <div
          className="reportElement"
          key={reportElement.reportElementID}
          style={SetReportElementStyle()}
          onClick={HandleReportElementClick}
          onDoubleClick={HandleReportElementDoubleClick}
        >
          {!reportImageMaximize && !reportDataMaximize && (
            <div
              className="reportElementHeader"
              style={SetReportElementHeaderStyle()}
              onClick={HandleReportElementHeaderClick}
              onDoubleClick={HandleReportElementHeaderDoubleClick}
            >
              <span>{reportElement.reportElementName}</span>
            </div>
          )}
          <div
            className="reportElementBody"
            style={SetReportElementBodyStyle()}
          >
            {!reportDataMaximize && (
              <div
                className="reportElementImage"
                onDoubleClick={HandleReportElementImageDoubleClick}
                style={SetReportElementImageStyle()}
              >
                <img
                  src={reportElement.reportElementImageUrl}
                  alt={`Report ${reportElement.reportElementID.toString()}`}
                />
              </div>
            )}
            {!reportImageMaximize && (
              <div
                className="reportElementData"
                onDoubleClick={HandleReportElementDataDoubleClick}
                style={SetReportElementDataStyle()}
              >
                <div className="reportElementComments">
                  {reportElement.reportElementComments &&
                    reportElement.reportElementComments.trim() !== "" &&
                    reportElement.reportElementComments
                      .split("<br>")
                      .map((comment, index) => {
                        replacements.forEach((replacement) => {
                          comment = comment.replace("/--/", replacement);
                        });
                        return comment.trim() !== "" ? (
                          <span key={index}>{comment}</span>
                        ) : (
                          <></>
                        );
                      })}
                </div>
                <div className="reportElementReviewTexts">
                  {reportElement.reportElementReviewTexts &&
                    reportElement.reportElementReviewTexts.trim() !== "" &&
                    reportElement.reportElementReviewTexts
                      .split("<br>")
                      .map((reviewText, index) =>
                        reviewText.trim() !== "" ? (
                          <span key={index}>{reviewText}</span>
                        ) : (
                          <></>
                        )
                      )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ReportElement;
