import { FC, Dispatch, useEffect, useState } from "react";
import {
  StopEventPropagation,
  UpdateUserLastActionDate,
} from "../../../helper/ui/methods";
import { UserInterface } from "../../../middleware/interfaces/user/user-interface";

interface responseDialogProps {
  user?: UserInterface;
  title: string;
  message: string;
  dispatch: Dispatch<any>;
}

const ResponseDialog: FC<responseDialogProps> = ({
  user,
  title,
  message,
  dispatch,
}) => {
  const [ok, setOk] = useState(false);

  const HandleResponseDialogClick = (event: React.MouseEvent) => {
    if (user) {
      StopEventPropagation(event);
      UpdateUserLastActionDate(user, dispatch);
    }
  };

  const HandleResponseDialogDoubleClick = (event: React.MouseEvent) => {
    StopEventPropagation(event);
  };

  useEffect(() => {
    if (ok) {
      setOk(false);
      dispatch({
        type: "RESPONSE",
        response: "",
      });
    }
  }, [ok]);

  return (
    <>
      {(() => {
        if (message !== "" && message !== undefined && message !== null) {
          if (message === "WAIT") {
            return (
              <div
                className="dialogCover"
                onClick={HandleResponseDialogClick}
                onDoubleClick={HandleResponseDialogDoubleClick}
              >
                <div className="dialog">
                  <h2 className="dialogTitle">Loading</h2>
                  <h4 className="dialogMessage">Please Wait!</h4>
                </div>
              </div>
            );
          } else {
            return (
              <div
                className="dialogCover"
                onClick={HandleResponseDialogClick}
                onDoubleClick={HandleResponseDialogDoubleClick}
              >
                <div className="dialog">
                  <h2 className="dialogTitle">{title}</h2>
                  <span className="dialogMessage">{message}</span>
                  <div className="dialogButtonWrapper">
                    <input
                      type="button"
                      name="ok"
                      value="Ok"
                      onClick={() => {
                        setOk(true);
                      }}
                    />
                  </div>
                </div>
              </div>
            );
          }
        } else {
          return <></>;
        }
      })()}
    </>
  );
};

export default ResponseDialog;
