import { FC, useEffect } from "react";
import { useAppStateContext } from "../context/state-context-provider";
import { UpdateUserWithServerResponse } from "../../helper/general/methods";
import { UserRegistration } from "../../core/database/user/user-registration";
import { UserAuthentication } from "../../core/database/user/user-authentication";
import { RecoverPassword } from "../../core/database/user/recover-password";
import { EditProfile } from "../../core/database/user/edit-profile";
import { ProjectEdit } from "../../core/database/project/project-edit";
import { ProjectCreate } from "../../core/database/project/project-create";
import { ProjectDelete } from "../../core/database/project/project-delete";
import { ProjectDownload } from "../../core/database/project/project-download";
import { ProjectShare } from "../../core/database/project/project-share";
import { ModelRetrieve } from "../../core/database/file/model-retrieve";
import { FileDownload } from "../../core/database/file/file-download";
import { FileDelete } from "../../core/database/file/file-delete";
import { FileUpload } from "../../core/database/file/file-upload";
import { FolderCreate } from "../../core/database/folder/folder-create";
import { FolderDelete } from "../../core/database/folder/folder-delete";
import { FolderDownload } from "../../core/database/folder/folder-download";
import { FolderEdit } from "../../core/database/folder/folder-edit";
import { ReportDelete } from "../../core/database/report/report-delete";
import { ReportDownload } from "../../core/database/report/report-download";
import { ReportRetrieve } from "../../core/database/report/report-retrieve";
import { ReportDescriptionRetrieve } from "../../core/database/report/report-description-retrieve";
import { ReportDescriptionUpload } from "../../core/database/report/report-description-upload";
import { ReportElementNoteUpload } from "../../core/database/report/report-elemment-note-upload";
import {
  CreateReportFromServerResponse,
  UpdateReportWithServerResponse,
} from "../../helper/report/methods";
import { ReportElementNoteEdit } from "../../core/database/report/report-elemment-note-edit";
import { ReportElementNoteDelete } from "../../core/database/report/report-element-note.delete";
import { UserRetrieve } from "../../core/database/user/user-retrieve";

export const CoreHandler: FC = () => {
  const [state, dispatch] = useAppStateContext();

  useEffect(() => {
    if (state.data) {
      // Define a common postCall function
      const postCall = async (operation: any) => {
        dispatch({
          type: "RESPONSE",
          response: "WAIT",
        });

        let serverResponse;
        switch (operation) {
          // USER SECTION
          case "RegistrationInterface":
            serverResponse = await UserRegistration.Register(state);
            break;
          case "AuthenticationInterface":
            serverResponse = await UserAuthentication.Authenticate(state);
            break;
          case "ProfileEditInterface":
            serverResponse = await EditProfile.Edit(state);
            break;
          case "RecoverPasswordInterface":
            serverResponse = await RecoverPassword.Recover(state);
            break;
          case "UserRetrieveInterface":
            serverResponse = await UserRetrieve.Retrieve(state);
            break;
          // PROJECT SECTION
          case "ProjectInterface":
            serverResponse = await ProjectCreate.Create(state);
            break;
          case "ProjectDeleteInterface":
            serverResponse = await ProjectDelete.Delete(state);
            break;
          case "ProjectDownloadInterface":
            serverResponse = await ProjectDownload.Download(state);
            break;
          case "ProjectShareInterface":
            serverResponse = await ProjectShare.Share(state);
            break;
          case "ProjectEditInterface":
            serverResponse = await ProjectEdit.Edit(state);
            break;
          // FOLDER SECTION
          case "FolderCreateInterface":
            serverResponse = await FolderCreate.Create(state);
            break;
          case "FolderDeleteInterface":
            serverResponse = await FolderDelete.Delete(state);
            break;
          case "FolderEditInterface":
            console.log(state.data);
            serverResponse = await FolderEdit.Edit(state);
            break;
          case "FolderDownloadInterface":
            serverResponse = await FolderDownload.Download(state);
            break;
          // FILE AND MODEL SECTION
          case "FileUploadInterface":
          case "ModelUploadInterface":
            serverResponse = await FileUpload.Upload(state);
            break;
          case "FileDeleteInterface":
            serverResponse = await FileDelete.Delete(state);
            break;
          case "FileDownloadInterface":
            serverResponse = await FileDownload.Download(state);
            break;
          case "ModelRetrieveInterface":
            serverResponse = await ModelRetrieve.Retrieve(state);
            break;
          // REPORT SECTION
          case "ReportDeleteInterface":
            serverResponse = await ReportDelete.Delete(state);
            break;
          case "ReportDownloadInterface":
            serverResponse = await ReportDownload.Download(state);
            break;
          case "ReportRetrieveInterface":
            serverResponse = await ReportRetrieve.Retrieve(state);
            break;
          case "ReportDescriptionRetrieveInterface":
            serverResponse = await ReportDescriptionRetrieve.Retrieve(state);
            break;
          case "ReportDescriptionUploadInterface":
            serverResponse = await ReportDescriptionUpload.Upload(state);
            break;
          case "ReportElementNoteUploadInterface":
            serverResponse = await ReportElementNoteUpload.Upload(state);
            break;
          case "ReportElementNoteEditInterface":
            serverResponse = await ReportElementNoteEdit.Edit(state);
            break;
          case "ReportElementNoteDeleteInterface":
            serverResponse = await ReportElementNoteDelete.Delete(state);
            break;
          default:
            return;
        }

        dispatch({
          type: "CLEAR_DATA",
        });

        if (typeof serverResponse === "string") {
          alert("STRING RESPONSE!");
          dispatch({
            type: "RESPONSE",
            response: serverResponse,
          });
        } else if (
          typeof serverResponse === "object" &&
          serverResponse !== null
        ) {
          if (operation === "ModelRetrieveInterface") {
            dispatch({
              type: "OPEN_MODEL",
              payload: serverResponse,
              response: "",
            });
          } else if (operation === "ReportRetrieveInterface") {
            dispatch({
              type: "OPEN_REPORT",
              payload: CreateReportFromServerResponse(serverResponse),
              response: "",
            });
          } else if (operation === "ReportDescriptionRetrieveInterface") {
            dispatch({
              type: "OPEN_REPORT_DESCRIPTION",
              payload: serverResponse,
              response: "",
            });
          } else if (
            operation === "ReportElementNoteUploadInterface" ||
            operation === "ReportElementNoteDeleteInterface" ||
            operation === "ReportElementNoteEditInterface"
          ) {
            const updatedReport = UpdateReportWithServerResponse(
              state.openReport!,
              serverResponse
            );
            dispatch({
              type: "OPEN_REPORT",
              payload: updatedReport,
              response: "",
            });
          } else {
            UpdateUserWithServerResponse(serverResponse, dispatch, state.user!);
          }
        }
      };

      // Execute postCall with the current operation
      postCall(state.data.type);
    }
  }, [state.data]);

  return <></>;
};
