import axios from "axios";
import { StateInterface } from "../../../middleware/interfaces/user/state-interface";
import { ProjectInterface } from "../../../middleware/interfaces/project/project-interface";

export const ProjectCreate = {
  Create: async (state: StateInterface) => {
    let serverResponse;
    if (state.data) {
      const projectCreateData: ProjectInterface = state.data;
      const url = "./php/project/project-creator-proxy.php";
      await axios
        .post(url, projectCreateData)
        .then((response) => {
          serverResponse = response.data;
        })
        .catch((error) => {
          serverResponse = error.message;
        });
    } else {
      serverResponse = "SOMETHING WENT WRONG! PLEASE CONTACT ADMIN.";
    }

    return serverResponse;
  },
};
