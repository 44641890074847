import axios from "axios";
import { StateInterface } from "../../../middleware/interfaces/user/state-interface";
import { FileUploadInterface } from "../../../middleware/interfaces/file/file-upload-interface";

export const FileUpload = {
  Upload: async (state: StateInterface) => {
    let serverResponse;
    if (state.data) {
      if (state.data.type === "FileUploadInterface") {
        const fileUploadData: FileUploadInterface = state.data;
        const url = "./php/file/file-uploader-proxy.php";
        await axios
          .post(url, fileUploadData)
          .then((response) => {
            serverResponse = response.data;
          })
          .catch((error) => {
            serverResponse = error.message;
          });
      } else if (state.data.type === "ModelUploadInterface") {
        const modelUploadData: FileUploadInterface = state.data;
        const url = "./php/file/file-uploader-proxy.php";
        await axios
          .post(url, modelUploadData, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            serverResponse = response.data;
          })
          .catch((error) => {
            serverResponse = error.message;
          });
      }
    } else {
      serverResponse = "SOMETHING WENT WRONG! PLEASE CONTACT ADMIN.";
    }

    return serverResponse;
  },
};
