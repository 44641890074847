import { Dispatch } from "react";
import { FileUploadInterface } from "../../middleware/interfaces/file/file-upload-interface";
import { UserInterface } from "../../middleware/interfaces/user/user-interface";
import { ProjectInterface } from "../../middleware/interfaces/project/project-interface";
import { FolderInterface } from "../../middleware/interfaces/folder/folder-interface";
import { FileInterface } from "../../middleware/interfaces/file/file-interface";
import { FileVersionInterface } from "../../middleware/interfaces/file/file-version-interface";
import { NavigateFunction } from "react-router-dom";
import { ReportInterface } from "../../middleware/interfaces/report/report-interface";
import { UserRetrieveInterface } from "../../middleware/interfaces/user/user-retrieve-interface";
import {
  PingServerWithLastActionDate,
  UpdateUserLastActionDate,
} from "../ui/methods";

export function FileToBase64(file: Blob): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const base64Data = reader.result as string;
      const base64Content = base64Data.split(",")[1]; // Extract the base64 data part after the comma
      resolve(base64Content);
    };

    reader.onerror = () => {
      reject(new Error("Failed to read the file as base64."));
    };

    reader.readAsDataURL(file);
  });
}

export function Base64ToUint8Array(base64: string): Uint8Array {
  const binaryString = window.atob(base64); // Decode base64
  const length = binaryString.length;
  const bytes = new Uint8Array(length);

  for (let i = 0; i < length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  return bytes;
}

export const CalculateTimeDifferenceInMinutes = (
  startTime: Date,
  endTime: Date
): number => {
  const differenceInMillis =
    new Date(endTime).getTime() - new Date(startTime).getTime();
  const differenceInMinutes = Math.floor(differenceInMillis / (1000 * 60));
  return differenceInMinutes;
};

export const FormatDateFE = (date: Date | string): string => {
  const formatedDate = new Date(date);
  const year = formatedDate.getUTCFullYear();
  const month = String(formatedDate.getUTCMonth() + 1).padStart(2, "0");
  const day = String(formatedDate.getUTCDate()).padStart(2, "0");
  // const hours = String(formatedDate.getHours()).padStart(2, "0");
  // const minutes = String(formatedDate.getMinutes()).padStart(2, "0");
  // const seconds = String(formatedDate.getSeconds()).padStart(2, "0");

  // return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  return `${year}-${month}-${day}`;
};

export const FormatDateBE = (date: Date | string): string => {
  const formatedDate = new Date(date);
  const year = formatedDate.getUTCFullYear();
  const month = String(formatedDate.getUTCMonth() + 1).padStart(2, "0");
  const day = String(formatedDate.getUTCDate()).padStart(2, "0");
  const hours = String(formatedDate.getUTCHours()).padStart(2, "0");
  const minutes = String(formatedDate.getUTCMinutes()).padStart(2, "0");
  const seconds = String(formatedDate.getUTCSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const BytesToMB = (bytes: number): string => {
  return (bytes / (1024 * 1024)).toFixed(2) + " MB";
};

export const PrepareUploadFile = (
  uploadFile: any,
  user: UserInterface,
  navigate: NavigateFunction,
  dispatch: Dispatch<any>
) => {
  const uploadFileName = uploadFile.name.split(".")[0].toLowerCase();
  const uploadFileType = uploadFile.name.split(".").reverse()[0].toLowerCase();
  const uploadFileSize = uploadFile.size;
  FileToBase64(uploadFile)
    .then((base64Data) => {
      // Use the base64Data as needed, e.g., send it to the server
      if (uploadFileType === "ifc") {
        navigate("/STANDARD");
        dispatch({
          type: "OPEN_MODEL",
          payload: {
            modelName: uploadFileName,
            modelFileData: base64Data,
            modelFileSize: uploadFile.size,
          },
          response: "WAIT",
        });
      } else {
        const uploadFileData: FileUploadInterface = {
          email: user.email,
          projectID: user.openProject!.projectID!,
          projectUserRole: user.openProject!.projectUserRole,
          fileParentID: user.openFolder ? user.openFolder.folderID : null,
          fileName: uploadFileName,
          fileType: uploadFileType,
          fileSize: uploadFileSize,
          fileUploadDate: FormatDateBE(new Date()),
          fileData: [base64Data],
          type: "FileUploadInterface",
        };
        dispatch({ type: "UPLOAD_FILE", payload: uploadFileData });
      }
    })
    .catch((error) => {
      console.error(error);
    });
};

export const UpdateUserWithServerResponse = async (
  serverResponse: any,
  dispatch: Dispatch<any>,
  user?: UserInterface
) => {
  if (serverResponse.status === "success") {
    if (serverResponse.header === "authentication") {
      const userRetrieveData: UserRetrieveInterface = {
        type: "UserRetrieveInterface",
      };
      dispatch({ type: "RETRIEVE_USER", payload: userRetrieveData });
    } else if (serverResponse.header === "retrieve") {
      const userData = await serverResponse.data;
      const updatedUser: UserInterface = {
        userName: userData["UserInformation"]["UserName"],
        email: userData["UserInformation"]["Email"],
        company: userData["UserInformation"]["Company"],
        accountStatus: userData["UserInformation"]["AccountStatus"],
        lastActionDate: new Date(),
        projects: ConvertRawDataToProjectsArray(
          Object.values(userData["ProjectInformation"])
        ),
        type: "UserInterface",
      };

      // Find the open project and folder based on the previous user’s state
      updatedUser.openProject = updatedUser.projects.find(
        (project) => project.projectID === user?.openProject?.projectID
      );

      updatedUser.openFolder = FindFolderByID(
        updatedUser.openProject?.projectFolders,
        user?.openFolder?.folderID
      );

      //Update Session
      UpdateUserLastActionDate(updatedUser, dispatch);

      // Dispatch the updated user state
      dispatch({
        type: "UPDATE_USER",
        payload: updatedUser,
        response: "",
      });
    } else if (serverResponse.header === "message") {
      dispatch({
        type: "RESPONSE",
        response: serverResponse.message,
      });
    }
  } else if (serverResponse.status === "error") {
    dispatch({
      type: "RESPONSE",
      response: serverResponse.message,
    });
  }
};

function ConvertRawDataToProjectsArray(rawProjects: any[]): ProjectInterface[] {
  return rawProjects.map(ConvertRawDataToProjectInterface);
}

function ConvertRawDataToProjectInterface(rawData: any): ProjectInterface {
  return {
    projectID: rawData.ProjectID,
    projectName: rawData.ProjectName,
    projectLocation: rawData.ProjectLocation,
    projectDescription: rawData.ProjectDescription,
    projectOwner: rawData.ProjectOwner,
    projectUserRole: rawData.ProjectUserRole,
    projectStartDate: rawData.ProjectStartDate,
    projectEndDate: rawData.ProjectEndDate,
    projectCreationDate: rawData.ProjectCreationDate,
    projectFiles: ConvertRawFiles(rawData.ProjectFiles || {}),
    projectFolders: ConvertRawFolders(rawData.ProjectFolders),
    projectReports: ConvertRawReports(rawData.ProjectReports),
    projectContentFilter: {
      reports: true,
      folders: true,
      filesAndModels: {
        models: true,
        files: true,
      },
      type: "ProjectContentFilterInterface",
    },
    type: "ProjectInterface",
  };
}

function ConvertRawDataToFileInterface(rawFile: any): FileInterface {
  const fileVersions = Object.entries(JSON.parse(rawFile.FileVersions)).reduce(
    (acc, [key, value]) => {
      const castValue = value as Omit<FileVersionInterface, "type">;
      return {
        ...acc,
        [key]: {
          fileSize: castValue.fileSize,
          fileUploadDate: new Date(castValue.fileUploadDate),
          fileUploadedBy: castValue.fileUploadedBy,
          type: "FileVersionInterface",
        },
      };
    },
    {}
  );
  return {
    projectID: rawFile.ProjectID,
    fileID: rawFile.FileID,
    fileParentID: rawFile.FileParentID,
    fileName: rawFile.FileName,
    fileType: rawFile.FileType,
    fileActiveVersion: Object.keys(fileVersions).length,
    fileVersions: fileVersions,
    fileSize: rawFile.FileSize,
    fileUploadDate: new Date(rawFile.FileUploadDate),
    fileUploadedBy: rawFile.FileUploadedBy,
    type: "FileInterface",
  };
}

function ConvertRawFiles(rawFilesObject: {
  [key: number]: any;
}): FileInterface[] {
  // Convert object values to array
  const rawFilesArray = Object.values(rawFilesObject);
  return rawFilesArray.map((rawFile) => ConvertRawDataToFileInterface(rawFile));
}

function ConvertRawFolders(rawFoldersObject: {
  [key: number]: any;
}): FolderInterface[] {
  const rawFoldersArray = Object.values(rawFoldersObject);

  return rawFoldersArray.map((rawFolder) => {
    // Initialize folder without children
    const folder: FolderInterface = {
      folderID: rawFolder.FolderID,
      projectID: rawFolder.ProjectID,
      folderParentID: rawFolder.FolderParentID,
      folderFiles: rawFolder.FolderFiles,
      folderChildren: rawFolder.FolderChildren, // Temporarily set as empty
      folderName: rawFolder.FolderName,
      folderCreationDate: new Date(rawFolder.FolderCreationDate),
      folderCreatedBy: rawFolder.FolderCreatedBy,
      type: "FolderInterface",
    };

    return folder;
  });
}

function ConvertRawReports(rawReportsObject: {
  [key: number]: any;
}): ReportInterface[] {
  const rawReportsArray = Object.values(rawReportsObject);

  return rawReportsArray.map((rawReport) => {
    const report: ReportInterface = {
      reportID: rawReport.ReportID,
      projectID: rawReport.ProjectID,
      reportName: rawReport.ReportName,
      reportSize: rawReport.ReportSize,
      reportCreationDate: new Date(rawReport.ReportCreationDate),
      reportUploadedBy: rawReport.ReportUploadedBy,
      reportClashCount: rawReport.ReportClashCount,
      type: "ReportInterface",
    };

    return report;
  });
}

export function FindFolderByID(
  folders: FolderInterface[] | undefined | null,
  folderID: number | undefined | null
): FolderInterface | undefined {
  if (!folders || !folderID) {
    return undefined; // Return undefined if folders is undefined
  }

  for (const folder of folders) {
    if (folder.folderID === folderID) {
      return folder; // Found the folder
    }

    // // If not found, recursively search in the children
    // const foundInChildren = FindFolderByID(folder.folderChildren, folderID);
    // if (foundInChildren) {
    //   return foundInChildren; // Found the folder in children
    // }
  }

  // If the folder is not found in this branch
  return undefined;
}
