import { FC, Dispatch, useEffect, useState } from "react";
import { UserInterface } from "../../../middleware/interfaces/user/user-interface";
import { FormatDateBE } from "../../../helper/general/methods";
import { ReportElementInterface } from "../../../middleware/interfaces/report/report-element-interface";
import {
  StopEventPropagation,
  UpdateUserLastActionDate,
} from "../../../helper/ui/methods";
import { ReportElementNoteUploadInterface } from "../../../middleware/interfaces/report/report-element-note-upload-interface";

interface reportElementNoteDialogProps {
  user: UserInterface;
  reportElementNoteDialogReveal: boolean;
  selectedReportElement: ReportElementInterface | null;
  setReportElementNoteDialogReveal: Dispatch<boolean>;
  dispatch: Dispatch<any>;
}

const ReportElementNoteDialog: FC<reportElementNoteDialogProps> = ({
  user,
  reportElementNoteDialogReveal,
  selectedReportElement,
  setReportElementNoteDialogReveal,
  dispatch,
}) => {
  const [reportElementNote, setReportElementNote] = useState("");
  const [submitReportElementNote, setSubmitReportElementNote] = useState(false);
  const [closeReportElementNote, setCloseReportElementNote] = useState(false);

  const HandleReportElementNoteClick = (event: React.MouseEvent) => {
    StopEventPropagation(event);
    UpdateUserLastActionDate(user, dispatch);
  };

  const HandleReportElementNoteDialogDoubleClick = (
    event: React.MouseEvent
  ) => {
    StopEventPropagation(event);
  };

  useEffect(() => {
    if (submitReportElementNote) {
      const reportElementNoteUploadData: ReportElementNoteUploadInterface = {
        email: user.email,
        reportElementID: selectedReportElement?.reportElementID!,
        reportElementNote: reportElementNote,
        reportElementNoteDate: FormatDateBE(new Date()),
        type: "ReportElementNoteUploadInterface",
      };
      dispatch({
        type: "SUBMIT_REPORT_ELEMENT_NOTE",
        payload: reportElementNoteUploadData,
      });
    }
    setSubmitReportElementNote(false);
    setReportElementNoteDialogReveal(false);
    setReportElementNote("");
  }, [submitReportElementNote]);

  useEffect(() => {
    setReportElementNoteDialogReveal(false);
    setCloseReportElementNote(false);
    setReportElementNote("");
  }, [closeReportElementNote]);

  return (
    <>
      {(() => {
        if (reportElementNoteDialogReveal) {
          return (
            <div
              className="dialogCover"
              onClick={HandleReportElementNoteClick}
              onDoubleClick={HandleReportElementNoteDialogDoubleClick}
            >
              <div id="reportElementNoteDialog">
                <h2 className="dialogTitle">
                  Report Element Note:
                  <br />
                  <span id="reporElementNoteTitle">
                    {selectedReportElement?.reportElementName}
                  </span>
                </h2>
                <div className="dialogTextInputWrapper">
                  <textarea
                    name="reportElementNote"
                    value={reportElementNote}
                    onChange={(e) => setReportElementNote(e.target.value)}
                  />
                </div>
                <div className="dialogButtonWrapper">
                  <input
                    type="button"
                    name="cancelReportElementNote"
                    value="Cancel"
                    onClick={() => setCloseReportElementNote(true)}
                  />
                  <input
                    type="button"
                    name="submitReportElementNote"
                    value="Submit"
                    onClick={() => setSubmitReportElementNote(true)}
                  />
                </div>
              </div>
            </div>
          );
        } else {
          return <></>;
        }
      })()}
    </>
  );
};

export default ReportElementNoteDialog;
