import axios from "axios";
import { StateInterface } from "../../../middleware/interfaces/user/state-interface";
import { ProfileEditInterface } from "../../../middleware/interfaces/user/profile-edit-interface";

export const EditProfile = {
  Edit: async (state: StateInterface) => {
    let serverResponse;
    if (state.data) {
      const editProfileData: ProfileEditInterface = state.data;
      const url = "./php/user/profile-editor-proxy.php";
      await axios
        .post(url, editProfileData)
        .then((response) => {
          serverResponse = response.data;
        })
        .catch((error) => {
          serverResponse = error.message;
        });
    } else {
      serverResponse = "SOMETHING WENT WRONG! PLEASE CONTACT ADMIN.";
    }

    return serverResponse;
  },
};
