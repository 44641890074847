import axios from "axios";
import { StateInterface } from "../../../middleware/interfaces/user/state-interface";
import { FileInterface } from "../../../middleware/interfaces/file/file-interface";

export const ModelRetrieve = {
  Retrieve: async (state: StateInterface) => {
    let serverResponse;
    if (state.data) {
      const modelRetrieve: FileInterface = state.data;
      const url = "./php/file/model-retriever-proxy.php";
      await axios
        .post(url, modelRetrieve)
        .then((response) => {
          serverResponse = response.data;
        })
        .catch((error) => {
          serverResponse = error.message;
        });
    } else {
      serverResponse = "SOMETHING WENT WRONG! PLEASE CONTACT ADMIN.";
    }

    return serverResponse;
  },
};
