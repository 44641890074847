import { FC, Dispatch, useEffect, useState } from "react";
import { UserInterface } from "../../../middleware/interfaces/user/user-interface";
import { FormatDateBE } from "../../../helper/general/methods";
import {
  StopEventPropagation,
  UpdateUserLastActionDate,
} from "../../../helper/ui/methods";
import { ReportElementNoteInterface } from "../../../middleware/interfaces/report/report-element-note-interface";
import { ReportElementNoteDeleteInterface } from "../../../middleware/interfaces/report/report-element-note-delete-interface";
import { ReportElementNoteEditInterface } from "../../../middleware/interfaces/report/report-element-note-edit-interface";

interface reportElementNoteEditDialogProps {
  user: UserInterface;
  selectedReportElementNote: ReportElementNoteInterface | null;
  setSelectedReportElementNote: Dispatch<ReportElementNoteInterface | null>;
  dispatch: Dispatch<any>;
}

const ReportElementNoteEditDialog: FC<reportElementNoteEditDialogProps> = ({
  user,
  selectedReportElementNote,
  setSelectedReportElementNote,
  dispatch,
}) => {
  const [reportElementNote, setReportElementNote] = useState("");
  const [submitReportElementNote, setSubmitReportElementNote] = useState(false);
  const [closeReportElementNote, setCloseReportElementNote] = useState(false);

  useEffect(() => {
    setReportElementNote(selectedReportElementNote?.reportElementNote || "");
  }, [selectedReportElementNote]);

  const HandleReportElementNoteClick = (event: React.MouseEvent) => {
    StopEventPropagation(event);
    UpdateUserLastActionDate(user, dispatch);
  };

  const HandleReportElementNoteDialogDoubleClick = (
    event: React.MouseEvent
  ) => {
    StopEventPropagation(event);
  };

  const ReportElementNoteIsReadOnly = () => {
    return selectedReportElementNote
      ? user.email !== selectedReportElementNote.email
      : true;
  };

  useEffect(() => {
    if (submitReportElementNote) {
      if (reportElementNote.trim() !== "") {
        const reportElementNoteEditData: ReportElementNoteEditInterface = {
          reportElementNoteID: selectedReportElementNote?.reportElementNoteID!,
          reportElementID: selectedReportElementNote?.reportElementID!,
          reportElementNote: reportElementNote,
          reportElementNoteEditDate: FormatDateBE(new Date()),
          type: "ReportElementNoteEditInterface",
        };

        dispatch({
          type: "SUBMIT_REPORT_ELEMENT_NOTE",
          payload: reportElementNoteEditData,
        });
      } else {
        const reportElementNoteDeleteData: ReportElementNoteDeleteInterface = {
          reportElementNoteID: selectedReportElementNote?.reportElementNoteID!,
          reportElementID: selectedReportElementNote?.reportElementID!,
          type: "ReportElementNoteDeleteInterface",
        };

        dispatch({
          type: "SUBMIT_REPORT_ELEMENT_NOTE",
          payload: reportElementNoteDeleteData,
        });
      }
    }
    setSelectedReportElementNote(null);
    setSubmitReportElementNote(false);
  }, [submitReportElementNote]);

  useEffect(() => {
    setSelectedReportElementNote(null);
    setCloseReportElementNote(false);
  }, [closeReportElementNote]);

  return (
    <>
      {(() => {
        if (selectedReportElementNote) {
          return (
            <div
              className="dialogCover"
              onClick={HandleReportElementNoteClick}
              onDoubleClick={HandleReportElementNoteDialogDoubleClick}
            >
              <div id="reportElementNoteDialog">
                <h2 className="dialogTitle">Edit Report Element Note:</h2>
                <div className="dialogTextInputWrapper">
                  <textarea
                    name="reportElementNote"
                    value={reportElementNote}
                    readOnly={ReportElementNoteIsReadOnly()}
                    onChange={(e) => setReportElementNote(e.target.value)}
                  />
                </div>
                <div className="dialogButtonWrapper">
                  <input
                    type="button"
                    name="cancelReportElementNote"
                    value="Cancel"
                    onClick={() => setCloseReportElementNote(true)}
                  />
                  <input
                    type="button"
                    name="submitReportElementNote"
                    value="Submit"
                    disabled={ReportElementNoteIsReadOnly()}
                    onClick={() => setSubmitReportElementNote(true)}
                  />
                </div>
              </div>
            </div>
          );
        } else {
          return <></>;
        }
      })()}
    </>
  );
};

export default ReportElementNoteEditDialog;
