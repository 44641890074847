import { Dispatch } from "react";
import { UserInterface } from "../../middleware/interfaces/user/user-interface";
import { StateInterface } from "../../middleware/interfaces/user/state-interface";
import {
  CalculateTimeDifferenceInMinutes,
  FindFolderByID,
  PrepareUploadFile,
} from "../general/methods";
import { NavigateFunction } from "react-router-dom";
import axios from "axios";
import { ServerResponseInterface } from "../../middleware/interfaces/server/server-response";

const allowedAFK = 45;
const pingInterval = 30000;
let intervalId: NodeJS.Timeout | null = null;

export const UpdateUserLastActionDate = (
  user: UserInterface | null,
  dispatch: Dispatch<any>
) => {
  console.log("USER ACTION");

  // Update last action date with each click
  if (user) {
    user.lastActionDate = new Date();

    const updateUserStatus = async () => {
      try {
        const serverResponse: any = await PingServerWithLastActionDate(user);
        console.log(serverResponse);
        if (
          typeof serverResponse === "string" ||
          (serverResponse as ServerResponseInterface).header === "afk"
        ) {
          if (intervalId) {
            clearInterval(intervalId);
            intervalId = null;
          }
          dispatch({ type: "UPDATE_USER", payload: null });
        }
      } catch (error) {
        if (intervalId) {
          clearInterval(intervalId);
          intervalId = null;
        }
        console.error("Error updating user status:", error);
        dispatch({ type: "UPDATE_USER", payload: null });
      }
    };

    // Start interval only if not already running
    if (!intervalId) {
      intervalId = setInterval(updateUserStatus, pingInterval);
    }
  } else {
    // Clear interval and reset state if user is null
    if (intervalId) {
      clearInterval(intervalId);
      intervalId = null;
    }
    dispatch({ type: "UPDATE_USER", payload: null });
  }
};

// Storing user in local storage
export const SaveUserToLocalStorage = (user: UserInterface | null) => {
  if (user !== null && user !== undefined) {
    try {
      const userData = {
        savedDate: user.lastActionDate,
        user: user,
      };
      const serializedUserData = JSON.stringify(userData);
      localStorage.setItem("serializedUserData", serializedUserData);
    } catch (error) {
      // Handle any error that occurs while storing the user
      console.error(
        "Error storing serialized user data in local storage:",
        error
      );
    }
  }
};

// Retrieving user from local storage
export const LoadUserFromLocalStorage = (): UserInterface | null => {
  try {
    const serializedUserData = localStorage.getItem("serializedUserData");
    if (serializedUserData) {
      const currentDate = new Date();
      const userData = JSON.parse(serializedUserData);
      const savedDate = new Date(userData.savedDate);
      const localStorageUser = userData.user;
      const timeDifference = CalculateTimeDifferenceInMinutes(
        savedDate,
        currentDate
      );
      if (timeDifference < allowedAFK) {
        return localStorageUser;
      } else {
        localStorage.removeItem("serializedUserData");
        return null;
      }
    }
  } catch (error) {
    // Handle any error that occurs while retrieving the user
    console.error(
      "Error retrieving serialized user data from local storage:",
      error
    );
    localStorage.removeItem("serializedUserData");

    return null;
  }

  return null; // Return undefined if no user is found
};

export const CheckAuthentication = async (dispatch: Dispatch<any>) => {
  dispatch({
    type: "RESPONSE",
    response: "WAIT",
  });
  const localStorageUser = await LoadUserFromLocalStorage();
  if (localStorageUser !== null) {
    dispatch({
      type: "UPDATE_USER",
      payload: localStorageUser,
    });
  } else {
    dispatch({ type: "LOGOUT", payload: null });
  }
};

export const HandleInputChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  setData: Dispatch<any>
) => {
  const { name, value } = e.target;
  setData((prevData: any) => ({
    ...prevData,
    [name]: value,
  }));
};

export const HandleDragOver = (
  event: React.DragEvent<HTMLDivElement>,
  setIsHovered: Dispatch<any>
) => {
  event.preventDefault();
  setIsHovered(true);
};

export const HandleDragLeave = (setIsHovered: Dispatch<any>) => {
  setIsHovered(false);
};

export const HandleDrop = (
  event: React.DragEvent<HTMLDivElement>,
  state: StateInterface,
  setIsHovered: Dispatch<any>,
  navigate: NavigateFunction,
  dispatch: Dispatch<any>
) => {
  event.preventDefault();
  setIsHovered(false);
  UpdateUserLastActionDate(state.user!, dispatch);
  const uploadFile = event.dataTransfer.files?.[0];
  if (uploadFile) {
    PrepareUploadFile(uploadFile, state.user!, navigate, dispatch);
  }
};

export const HandleLogOut = (dispatch: Dispatch<any>) => {
  localStorage.removeItem("serializedUserData");
  dispatch({ type: "LOGOUT", payload: null });
};

export const HandleReturn = (
  state: StateInterface,
  dispatch: Dispatch<any>
) => {
  if (
    state.user?.openProject &&
    !state.user?.openFolder &&
    !state.user?.openReport
  ) {
    const updatedUser = state.user;
    updatedUser!.openProject = null;
    dispatch({
      type: "UPDATE_USER",
      payload: updatedUser,
      response: "",
    });
  } else if (state.user?.openFolder) {
    const updatedUser = state.user;
    updatedUser!.openFolder = FindFolderByID(
      state.user?.openProject?.projectFolders,
      state.user?.openFolder.folderParentID
    );
    dispatch({
      type: "UPDATE_USER",
      payload: updatedUser,
      response: "",
    });
  } else if (state.user?.openReport) {
    const updatedUser = state.user;
    updatedUser!.openReport = null;
    dispatch({
      type: "UPDATE_USER",
      payload: updatedUser,
      response: "",
    });
    dispatch({ type: "CLOSE_REPORT" });
  }
};

export const OpenImageInNewWindow = (src: string) => {
  window.open(src, "_blank");
};

export function OpenPageInNewWindow(
  destinationPage: string,
  data: object = {}
) {
  const reportPageUrl = `${
    window.location.origin
  }/#/${destinationPage}?data=${encodeURIComponent(JSON.stringify(data))}`;
  window.open(reportPageUrl, "_blank");
}

export const StopEventPropagation = (event: React.MouseEvent) => {
  event.stopPropagation();
};

export const PingServerWithLastActionDate = async (user: UserInterface) => {
  let serverResponse;
  if (user) {
    const userData: UserInterface = user;
    const url = "./php/server/session-updater-proxy.php";
    await axios
      .post(url, userData)
      .then((response) => {
        serverResponse = response.data as ServerResponseInterface;
      })
      .catch((error) => {
        serverResponse = error.message;
      });
  } else {
    serverResponse = "SOMETHING WENT WRONG! PLEASE CONTACT ADMIN.";
  }

  return serverResponse;
};
