import { FC, Dispatch, useEffect, useState } from "react";
import { ReportInterface } from "../../../middleware/interfaces/report/report-interface";
import { ReportDescriptionInterface } from "../../../middleware/interfaces/report/report-description-interface";
import { UserInterface } from "../../../middleware/interfaces/user/user-interface";
import { ReportDescriptionUploadInterface } from "../../../middleware/interfaces/report/report-description-upload-interface";
import { FormatDateBE } from "../../../helper/general/methods";

interface reportDescriptionDialogProps {
  user: UserInterface;
  reportDescriptionObject: ReportDescriptionInterface | null | undefined;
  selectedReport: ReportInterface;
  dispatch: Dispatch<any>;
}

const ReportDescriptionDialog: FC<reportDescriptionDialogProps> = ({
  user,
  reportDescriptionObject,
  selectedReport,
  dispatch,
}) => {
  const [reportDescription, setReportDescription] = useState("");
  const [submitReportDescription, setSubmitReportDescription] = useState(false);
  const [closeReportDescription, setCloseReportDescription] = useState(false);

  useEffect(() => {
    setReportDescription(reportDescriptionObject?.reportDescription || "");
  }, [reportDescriptionObject]);

  useEffect(() => {
    if (submitReportDescription) {
      const reportDescriptionUploadData: ReportDescriptionUploadInterface = {
        email: user.email,
        reportID: reportDescriptionObject?.reportID!,
        reportDescription: reportDescription,
        reportDescriptionUploadDate: FormatDateBE(new Date()),
        type: "ReportDescriptionUploadInterface",
      };
      dispatch({
        type: "SUBMIT_REPORT_DESCRIPTION",
        payload: reportDescriptionUploadData,
      });
      dispatch({ type: "CLOSE_REPORT_DESCRIPTION" });
    }
    setSubmitReportDescription(false);
  }, [submitReportDescription]);

  useEffect(() => {
    if (closeReportDescription) {
      dispatch({ type: "CLOSE_REPORT_DESCRIPTION" });
    }
    setCloseReportDescription(false);
  }, [closeReportDescription]);

  return (
    <>
      {(() => {
        if (reportDescriptionObject) {
          return (
            <div className="dialogCover">
              <div id="reportDescriptionDialog">
                <h2 className="dialogTitle">
                  Report Description:
                  <br />
                  <span id="reporDescriptionTitle">
                    {selectedReport.reportName}
                  </span>
                </h2>
                <div className="dialogTextInputWrapper">
                  <textarea
                    name="description"
                    value={reportDescription}
                    readOnly={user.openProject?.projectUserRole === "USER"}
                    onChange={(e) => setReportDescription(e.target.value)}
                  />
                </div>
                <div className="dialogButtonWrapper">
                  <input
                    type="button"
                    name="cancelDescription"
                    value="Cancel"
                    onClick={() => setCloseReportDescription(true)}
                  />
                  <input
                    type="button"
                    name="submitDescription"
                    value="Submit"
                    disabled={user.openProject?.projectUserRole === "USER"}
                    onClick={() => setSubmitReportDescription(true)}
                  />
                </div>
              </div>
            </div>
          );
        } else {
          return <></>;
        }
      })()}
    </>
  );
};

export default ReportDescriptionDialog;
