import { FC, Dispatch, useState, useEffect } from "react";
import { FormatDateFE } from "../../../helper/general/methods";
import { DecisionInterface } from "../../../middleware/interfaces/user/decision-interface";
import { FolderInterface } from "../../../middleware/interfaces/folder/folder-interface";
import { UserInterface } from "../../../middleware/interfaces/user/user-interface";
import { FolderDeleteInterface } from "../../../middleware/interfaces/folder/folder-delete-interface";
import { FolderDownloadInterface } from "../../../middleware/interfaces/folder/folder-download-interface";

interface folderInfoProps {
  user: UserInterface;
  selectedFolder: FolderInterface;
  setSelectedFolder: Dispatch<any>;
  setEditFolderDialogReveal: Dispatch<any>;
  dispatch: Dispatch<any>;
}

const FolderInfo: FC<folderInfoProps> = ({
  user,
  selectedFolder,
  setSelectedFolder,
  setEditFolderDialogReveal,
  dispatch,
}) => {
  const [openFolder, setOpenFolder] = useState(false);
  const [deleteFolderDialogReveal, setDeleteFolderDialogReveal] =
    useState(false);
  const [downloadFolder, setDownloadFolder] = useState(false);
  const [editFolder, setEditFolder] = useState(false);

  useEffect(() => {
    if (openFolder === true) {
      const updatedUser = user;
      updatedUser!.openFolder = selectedFolder;
      dispatch({
        type: "UPDATE_USER",
        payload: updatedUser,
        response: "",
      });
      setOpenFolder(false);
      setSelectedFolder(null);
    }
  }, [openFolder]);

  useEffect(() => {
    if (deleteFolderDialogReveal) {
      const deleteFolderData: FolderDeleteInterface = {
        email: user.email,
        folderID: selectedFolder.folderID,
        projectID: user.openProject?.projectID!,
        projectUserRole: user.openProject?.projectUserRole!,
        type: "FolderDeleteInterface",
      };
      const decision: DecisionInterface = {
        title: "Delete Folder",
        message:
          "You have selected a folder to delete. Are you sure you want to continue?",
        decisionOptions: ["DELETE_FOLDER", "NO_ACTION"],
        result: "",
        data: deleteFolderData,
        type: "DecisionInterface",
      };
      dispatch({ type: "DECISION", decision: decision });
    }
    setDeleteFolderDialogReveal(false);
  }, [deleteFolderDialogReveal]);

  useEffect(() => {
    if (downloadFolder === true) {
      const downloadFolderData: FolderDownloadInterface = {
        email: user.email,
        projectUserRole: user.openProject!.projectUserRole,
        folderID: selectedFolder.folderID,
        projectID: selectedFolder.projectID,
        type: "FolderDownloadInterface",
      };
      const decision: DecisionInterface = {
        title: "Download Folder",
        message:
          "You have selected a folder to download. Are you sure you want to continue?",
        decisionOptions: ["DOWNLOAD_FOLDER", "NO_ACTION"],
        result: "",
        data: downloadFolderData,
        type: "DecisionInterface",
      };
      dispatch({ type: "DECISION", decision: decision });
    }
    setDownloadFolder(false);
  }, [downloadFolder]);

  useEffect(() => {
    if (editFolder === true) {
      setEditFolderDialogReveal("EDIT");
    }
    setEditFolder(false);
  }, [editFolder]);

  return (
    <>
      {(() => {
        if (selectedFolder) {
          return (
            <div className="itemDetails">
              <span> Name: {selectedFolder.folderName} </span>
              <span> Created By: {selectedFolder.folderCreatedBy} </span>
              <span>
                Creation Date:{" "}
                {FormatDateFE(selectedFolder.folderCreationDate!)}
              </span>
              <div className="horizontalDetailsOptionWrapper">
                <div className="menuButton">
                  <img
                    className="icon-small"
                    src="assets/icons/enter.png"
                    alt="OPEN"
                    onClick={() => {
                      setOpenFolder(true);
                    }}
                  />
                </div>
                <div
                  className="menuButton"
                  onClick={() => {
                    setDownloadFolder(true);
                  }}
                >
                  <img
                    className="icon-small"
                    src="assets/icons/download.png"
                    alt="DOWNLOAD"
                  />
                </div>
                <div
                  className="menuButton"
                  onClick={() => {
                    setEditFolder(true);
                  }}
                >
                  <img
                    className="icon-small"
                    src="assets/icons/edit.png"
                    alt="EDIT"
                  />
                </div>
                <div
                  className="menuButton"
                  onClick={() => {
                    setDeleteFolderDialogReveal(true);
                  }}
                >
                  <img
                    className="icon-small"
                    src="assets/icons/delete.png"
                    alt="DELETE"
                  />
                </div>
              </div>
            </div>
          );
        } else {
          return <></>;
        }
      })()}
    </>
  );
};

export default FolderInfo;
