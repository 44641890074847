import { Dispatch, FC } from "react";
import ReportElement from "./report-element";
import { ReportInterface } from "../../../middleware/interfaces/report/report-interface";
import { UserInterface } from "../../../middleware/interfaces/user/user-interface";
import { ReportElementInterface } from "../../../middleware/interfaces/report/report-element-interface";

interface reportContentProps {
  user: UserInterface;
  report: ReportInterface;
  userInputElementCount: number;
  selectedReportElement: ReportElementInterface | null;
  setSelectedReportElement: Dispatch<ReportElementInterface | null>;
  setReportElementNoteDialogReveal: Dispatch<boolean>;
  dispatch: Dispatch<any>;
}

const ReportContent: FC<reportContentProps> = ({
  user,
  report,
  userInputElementCount,
  selectedReportElement,
  setSelectedReportElement,
  setReportElementNoteDialogReveal,
  dispatch,
}) => {
  const headerReportElement = report.reportElements?.find(
    (element) => element.reportElementName === "logo"
  );

  const bodyReportElements = report.reportElements?.filter(
    (element) => element.reportElementName !== "logo"
  );

  const RenderedReportElements = bodyReportElements?.map((reportElement) => {
    const reportElementGroup =
      report.reportContentFilter?.reportElementGroupData.find(
        (element) =>
          element.reportElementGroupName ===
          reportElement.reportElementGroupName
      );

    return (
      <ReportElement
        key={reportElement.reportElementID}
        user={user}
        reportElement={reportElement}
        userInputElementCount={userInputElementCount}
        selectedReportElement={selectedReportElement}
        setSelectedReportElement={setSelectedReportElement}
        setReportElementNoteDialogReveal={setReportElementNoteDialogReveal}
        filter={
          reportElementGroup!.reportElementGroupData.reportElementGroupFilter
        }
        dispatch={dispatch}
      />
    );
  });

  return (
    <div id="reportWrapper">
      {headerReportElement && (
        <div id="reportHeader">
          <img
            src={headerReportElement.reportElementImageUrl}
            alt={`Report ${headerReportElement.reportElementID.toString()}`}
          />
        </div>
      )}
      <div id="reportBody">
        {bodyReportElements?.length ? (
          RenderedReportElements
        ) : (
          <p>No report elements available.</p>
        )}
      </div>
    </div>
  );
};

export default ReportContent;
